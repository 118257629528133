@use "@angular/material" as mat;
$background-tone: white;
$foreground-tone: black;

$grey1: #edf0f3;
$grey1-contrast: black;
$grey2: #404040;
$grey2-contrast: black;
$grey3: #979797;
$grey3-contrast: black;
// $grey4: #1c1c1c;
// $grey4-contrast: white;
$grey5: #7A7A8D;
$grey5-contrast: white;
$grey6: #485A72;
$grey6-contrast: white;
$grey7: #4E4E4E;
$grey7-contrast: white;
$grey8: #f8f8f8;
$grey8-contrast: black;
$grey9: #d8d8d8;
$grey9-contrast: black;
$grey10: #627087;
$grey10-contrast: white;
$grey11: #e0e0e0;
$grey11-contrast: black;

$orange: #fcaa35;
$orange-contrast: black;

$blue1: #20a1f1;
$blue1-contrast: white;

$blue2: #100d2c;
$blue2-contrast: white;

$blue3: #0184E8;
$blue3-contrast: white;

$blue4: #2c2648;
$blue4-contrast: white;

$blue5: #0000ee;
$blue5-contrast: white;

$green1: #078442;
$green1-contrast: white;

$green2: #CCE7D7;
$green2-contrast: black;

$green3: #008839;
$green3-contrast: white;

$green4: #DCFCE7;
$green4-hover:#BBF7D0;
$green4-selected: #4ADE80;
$green4-contrast: black;

$red1: #EB0C00;
$red1-contrast: white;

$red2: #FEE2E2;
$red2-hover: #FECACA;
$red2-selected: #F87171;
$red2-contrast: black;

$pink1: #DF4661;
$pink1-contrast: white;

$pink2: #FF8D8D;
$pink2-contrast: black;

$purple1: #6F0BFF;
$purple1-contrast: white;

$purple2: #6D28D9;
$purple2-contrast: white;

$yellow1: #EFE300;
$yellow1-contrast: black;

$yellow2: #D69E2E;
$yellow2-contrast: black;

$yellow3: #FEF9C3;
$yellow3-hover: #FEF08A;
$yellow3-selected: #FACC15;
$yellow3-contrast: black;

$text-default: $blue2;


$highlight: #1D4ED8;
$highlight-contrast: white;


$shadow1: $grey11;

$border1: $grey9;
$border2: $grey3;
$border3: $grey7;
$border4: $grey1;
$border5: #e7e7e7;
$border6: $grey6;




$body-text1: $blue2;
$body-text2: $grey10;
$body-text3: #7a8a9f;
$body-text4: #c0c0c0;
$button1: $grey8;
$button2: #fdfdfd;
$button3: $grey11;
// $button4: #d7d7d7;
$upcoming-blue: $blue2;
$live-green: #00804F;
$final-red: $red1;
$paused-grey: $grey10;
$draw-grey: #7C8AA7;
// $highlight: #df4661;
// $highlight-contrast: white;
// $shadow1: $grey11;
// $border1: #e7e7e7;
// $border2: #485a72;
// $foreground-tone: black;
// $background-tone: white;
$shortlist-gold: #f8d64e;

$left-team-colour: $highlight;
$right-team-colour: $upcoming-blue;

$block-background: $background-tone;
$highlight-lighter: lighten($highlight, 10%);
$upcoming-blue-lighter: lighten($upcoming-blue, 10%);
$block-toggle-selected: $blue2;
$block-toggle-selected-contrast: $blue2-contrast;
$prob-progression-background: rgba($highlight, 0.7);

$dark-palette: (
	50: #e4e5ea,
	100: #BABDCD,
	200: #8f93ab,
	300: #666b8a,
	400: #494e74,
	500: #2e3360,
	600: #282d59,
	700: #21254F,
	800: #1a1d43,
	900: #100d2c,
	contrast: (
		50: black,
		100: black,
		200: black,
		300: black,
		400: black,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
	)
);

$highlight-palette: (
	50: #E8EAFB,
	100: #C4CAF4,
	200: #9ca8ec,
	300: #7186E5,
	400: #4d6adf,
	500: #1d4ed8,
	600: #1547CD,
	700: #003CC1,
	800: #0031B5,
	900: #001CA3,
	contrast: (
		50: black,
		100: black,
		200: black,
		300: black,
		400: black,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
	)
);

$my-primary: mat.define-palette($dark-palette, 900);
$my-accent: mat.define-palette($highlight-palette, 500);

$my-theme: mat.define-light-theme((
	color: (
		primary: $my-primary,
		accent: $my-accent,
	)
));
